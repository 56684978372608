/* eslint-disable react/prop-types */

import React from 'react';
import Avatar from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';
import { getCdnUrl } from '../../helpers/get-cdn-url';
import { ReactComponent as SiiIconImage } from './sii_icon.svg';
import { ReactComponent as SatIconImage } from './sat_icon.svg';

const SantanderImage = getCdnUrl('/common_assets/assets/icons/santander_image.png');
const FingoImage = getCdnUrl('/common_assets/assets/icons/fingo_image.png');

const SiiIcon = (props) => {
  const {
    sx: { color },
  } = props;
  return (
    <SvgIcon {...props} alt="sii-icon">
      <SiiIconImage fill={color} />
    </SvgIcon>
  );
};

const SatIcon = (props) => {
  const {
    sx: { color },
  } = props;
  return (
    <SvgIcon {...props} alt="sat-icon">
      <SatIconImage fill={color} />
    </SvgIcon>
  );
};

const SantanderIcon = () => (
  <Avatar src={SantanderImage} sx={{ width: 17, height: 17 }} />
);

const FingoIcon = () => (
  <Avatar
    src={FingoImage}
    sx={{ width: 17, height: 17 }}
    style={{ borderRadius: 0 }}
  />
);

const requestingPlatformMapperImage = {
  SANTANDER: SantanderImage,
  FINGO: FingoImage,
};

const requestingPlatformMapperIcon = {
  SANTANDER: SantanderIcon,
  FINGO: FingoIcon,
};

export {
  SiiIcon,
  SatIcon,
  requestingPlatformMapperImage,
  requestingPlatformMapperIcon,
};
